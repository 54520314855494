body {
  margin: 0;
  padding: 0;
}
html,
body {
  height: -webkit-fill-available;
}

#root {
  height: -webkit-fill-available;
}
#root > .fallback {
  min-height: 100vh;
}
.m-l {
  margin-left: 4px !important;
}

.m-l-2 {
  margin-left: 8px !important;
}
.m-r {
  margin-left: 4px !important;
}

.m-r-2 {
  margin-left: 8px !important;
}
.sticky-member-ranglist {
  position: fixed !important;
  bottom: 0;
  z-index: 10;
  box-shadow: inset 0px 1px 0px #e6eefa !important;
}
.text-decoration-none {
  text-decoration: none;
}
.lazy-load-image-background.blur {
  -webkit-filter: blur(2px);
  filter: blur(2px);
}
.lazy-load-image-background.blur.lazy-load-image-loaded {
  -webkit-filter: blur(0);
  filter: blur(0);
  -webkit-transition: -webkit-filter 0.3s;
  transition: -webkit-filter 0.3s;
  -o-transition: filter 0.3s;
  transition: filter 0.3s, -webkit-filter 0.3s;
}
// disable blur on ios, because pictures are cutted into box with inner scroll
#simple-tabpanel-1 {
  @supports (-webkit-touch-callout: none) {
    .lazy-load-image-background.blur {
      filter: none;
    }
  }
}
.privacy-page {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
#cssAnimInfiniteRotating {
  animation: rotate 1.5s linear infinite;
  transform-origin: center;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.usercentrics-is-hidden #usercentrics-root {
  display: none;
}

.text-overflow-ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.text-ellipsis-3lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#animationFadeIn {
  animation: fadein 0.4s ease-in;
}

@keyframes slideInBottom {
  from {
    opacity: 0.4;
    top: 90vh;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

#animationSlideInBottom {
  animation: slideInBottom 0.4s ease-in-out;
}

@keyframes slideInTop {
  0% {
    transform: translateY(-200px);
  }
  30% {
    transform: translateY(30px);
  }
  50% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(20px);
  }
  70% {
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(10px);
  }
  90% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

#animationSlideInTop {
  animation: slideInTop 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
}

@keyframes Ringing {
  0%,
  100% {
    transform: rotate(0deg);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: rotate(-1deg);
  }
  20%,
  40%,
  60%,
  80% {
    transform: rotate(1deg);
  }
}

#animationRinging {
  animation: Ringing 0.5s ease-in-out 2;
}

@keyframes glow {
  0%,
  100% {
    box-shadow: 0 0 2.5px #c7f0fb, 0 0 5px #c7f0fb, 0 0 7.5px #c7f0fb,
      0 0 10px #c7f0fb;
  }
  50% {
    box-shadow: 0 0 5px #a5d1e3, 0 0 10px #a5d1e3, 0 0 15px #a5d1e3,
      0 0 20px #a5d1e3;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0) scale(1, 1);
  }
  20% {
    transform: translateY(-5px) scale(0.99, 1.01);
  }
  50% {
    transform: translateY(0) scale(1.01, 0.99);
  }
  70% {
    transform: translateY(-2.5px) scale(0.995, 1.005);
  }
  90% {
    transform: translateY(0) scale(1.005, 0.995);
  }
}

#glowingBanner {
  animation: glow 1s 4, bounce 1s 4;
}
